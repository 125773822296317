import React from 'react';
import style from './New.module.scss';
const New = () => {
    return (
        <div className={style.NewSection} id="new">
            <h2>News</h2>
            <div className={style.NewContainer}>
                <div className={style.image2}>
                    <img src="./nouvelle_annee_2025.jpg" alt="new"/>
                </div>
                <div className={style.image2}>
                    <img src="./salutation_soleil_1er_fevrier.jpg" alt="new"/>
                </div>
                {/*si je veux ajouter une image prendre la meme classe image2*/}
            </div>
        </div>
    );
};
export default New;